import * as React from "react"
import axios from "axios"
import clsx from "clsx"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import IconButton from "@material-ui/core/IconButton"
import Badge from "@material-ui/core/Badge"
import CircularProgress from "@material-ui/core/CircularProgress"

import handleError from "../../../helperFunc/handleError"
import { addSingleOrder } from "../helperFunc/sectionMainImportFilter"
import { mainPrintImport, sectionPrint } from "../helperFunc/mainPrintFilter"

import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import WhatshotOutlinedIcon from "@mui/icons-material/WhatshotOutlined"
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined"
import DoneAllOutlinedIcon from "@mui/icons-material/DoneAllOutlined"
import SettingsBackupRestoreOutlinedIcon from "@mui/icons-material/SettingsBackupRestoreOutlined"
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  footer: {
    position: "absolute",
    borderBottomRightRadius: "0.5rem",
    borderBottomLeftRadius: "0.5rem",
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    bottom: 0,
    left: 0,
    width: "100%",
    backgroundColor: ({ selectedDetailLvl }) =>
      selectedDetailLvl.value === "Detailed" ||
      selectedDetailLvl.value === "Brief"
        ? `${theme.palette.common.blackThree}c0`
        : `${theme.palette.common.blackTwo}`,

    backdropFilter: "blur(5px)",
    "-webkit-backdrop-filter": "blur(5px)",
  },

  butPrintNPrep: {
    width: "6rem !important",
    borderRadius: "2rem !important",
  },
  butEndIcons: {
    margin: "0 !important",
  },
  printProg: {
    marginRight: "0.5rem",
  },

  but: {
    width: "3.25rem",
    height: "3.25rem",
    padding: "0rem !important",
    backgroundColor: `${theme.palette.common.black}a0`,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: `transparent !important`,
    },
  },

  butReceived: {
    backgroundColor: `${theme.palette.common.received}`,
  },

  butDone: {
    backgroundColor: `${theme.palette.common.done}`,
  },

  butDoneDanger: {
    animationName: `$myEffectDone`,
    animationIterationCount: "infinite",
    animationDuration: "2s",
    animationTimingFunction: `${theme.transitions.easing.easeInOut}`,
  },

  "@keyframes myEffectDone": {
    "0%": {
      backgroundColor: `${theme.palette.common.done}`,
    },
    "10%": {
      backgroundColor: `${theme.palette.common.red}`,
    },
    "80%": {
      backgroundColor: `${theme.palette.common.done}`,
    },
    "100%": {
      backgroundColor: `${theme.palette.common.done}`,
    },
  },

  butReady: {
    backgroundColor: `${theme.palette.common.ready}`,
  },

  butReadyDanger: {
    animationName: `$myEffectReady`,
    animationIterationCount: "infinite",
    animationDuration: "2s",
    animationTimingFunction: `${theme.transitions.easing.easeInOut}`,
  },

  "@keyframes myEffectReady": {
    "0%": {
      backgroundColor: `${theme.palette.common.ready}`,
    },
    "10%": {
      backgroundColor: `${theme.palette.common.red}`,
    },
    "80%": {
      backgroundColor: `${theme.palette.common.ready}`,
    },
    "100%": {
      backgroundColor: `${theme.palette.common.ready}`,
    },
  },

  butPreparing: {
    backgroundColor: `${theme.palette.common.preparing}`,
  },

  butDisabled: {
    backgroundColor: `${theme.palette.common.blackTwo}a0 !important`,
    color: `${theme.palette.common.blackFour} !important`,
  },

  butText: {
    textTransform: "initial",
    fontSize: "0.75rem",
  },
  iconSize: {
    fontSize: "2rem !important",
  },

  printIconSize: {
    fontSize: "2rem !important",
  },

  statusGridWarning: {
    backgroundColor: `${theme.palette.common.darkOrange}`,
  },
  statusGridPreparing: {
    backgroundColor: `${theme.palette.common.preparing}`,
  },
  statusGridReady: {
    backgroundColor: `${theme.palette.common.ready}`,
  },
  statusGridDone: {
    backgroundColor: `${theme.palette.common.done}`,
  },
  checkCircleIcon: {
    fontSize: "1.5rem !important",
    color: `${theme.palette.common.green} !important`,
  },
}))

const OrderListItemFooter = ({
  orderItem,
  ordersIn,
  user,
  dispatchFeedback,
  setAlarm,
  setOrdersIn,
  setCurrentOrdCount,
  kitchenSettings,
  section,
  printer,
  selectedDetailLvl,
  isAllLoading,
  setIsAllLoading,
  setPrintAlert,
}) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [isPrinterLoading, setIsPrinterLoading] = React.useState(false)
  const isMounted = React.useRef(false)

  const classes = useStyles({ selectedDetailLvl })
  const maxPrepTime = kitchenSettings.maxPreparingTime

  const sendStateChange = (toState, isPrintSuccess) => {
    setIsLoading(true)

    axios
      .post(
        process.env.GATSBY_STRAPI_URL +
          "/api/kitchen-setting/set-ord-section-status",
        {
          id: orderItem.id,
          newSectionStatus: toState,
          targetSection: section,
          isPrinted: isPrintSuccess,
        },
        {
          headers: {
            Authorization: `Bearer ${user.jwt}`,
          },
        }
      )
      .then(response => {
        if (isMounted.current) {
          setIsLoading(false)
        }

        const res = addSingleOrder(
          ordersIn,
          response.data,
          kitchenSettings,
          section
        )
        if (res) {
          setAlarm(res.alarm)
          setCurrentOrdCount(res.ordCount)
          setOrdersIn(res.orderRes)
        }
      })
      .catch(error => {
        console.error(error)

        if (isMounted.current) {
          setIsLoading(false)
        }
        handleError(error, dispatchFeedback)
      })
  }

  const handleChangePrintState = orderItemCopy => {
    axios
      .post(
        process.env.GATSBY_STRAPI_URL +
          "/api/kitchen-setting/set-ord-section-is-printed",
        {
          id: orderItemCopy.id,
          targetSection: section,
        },
        {
          headers: {
            Authorization: `Bearer ${user.jwt}`,
          },
        }
      )
      .then(response => {
        setIsAllLoading(false)
        if (isMounted.current) {
          setIsPrinterLoading(false)
        }
        const res = addSingleOrder(
          ordersIn,
          response.data,
          kitchenSettings,
          section
        )
        if (res) {
          setOrdersIn(res.orderRes)
          setAlarm(res.alarm)
          setCurrentOrdCount(res.ordCount)
        }
      })
      .catch(error => {
        console.error(error)
        setIsAllLoading(false)
        if (isMounted.current) {
          setIsPrinterLoading(false)
        }
        handleError(error, dispatchFeedback)
      })
  }

  const handlePrint = orderItemCopy => {
    if (printer.current) {
      setIsAllLoading(true)
      setIsPrinterLoading(true)
      let printsPerBatch = 7
      let printFailed = false
      let printData = mainPrintImport(orderItemCopy, kitchenSettings.sections)

      printer.current.onreceive = function (res) {
        if (res.success) {
          let printjobid = res.printjobid
          let printjobidarray = printjobid.split("BATCH")[1].split("COUNT")
          let batchNo = parseInt(printjobidarray[0])
          let count = parseInt(printjobidarray[1])
          let totalPrintsBatch = Math.ceil(
            printData[count].items.length / printsPerBatch
          )

          if (batchNo >= totalPrintsBatch && count + 1 >= printData.length) {
            if (!orderItemCopy.sectionsObj[section].isPrinted) {
              handleChangePrintState(orderItemCopy)
            } else {
              setIsAllLoading(false)
              if (isMounted.current) {
                setIsPrinterLoading(false)
              }
            }
          } else if (batchNo < totalPrintsBatch) {
            sectionPrint(
              orderItemCopy,
              printData[count],
              printer,
              printsPerBatch,
              batchNo + 1,
              count
            )
          } else if (count < printData.length) {
            let prntSent = false
            for (let i = count + 1; i < printData.length; i++) {
              if (printData[i].section.slug === section) {
                sectionPrint(
                  orderItemCopy,
                  printData[i],
                  printer,
                  printsPerBatch,
                  1,
                  i
                )
                prntSent = true
                break
              }
            }
            if (!prntSent) {
              if (!orderItemCopy.sectionsObj[section].isPrinted) {
                handleChangePrintState(orderItemCopy)
              } else {
                setIsAllLoading(false)
                if (isMounted.current) {
                  setIsPrinterLoading(false)
                }
              }
            }
          }
        }

        if (!res.success) {
          if (!printFailed) {
            printFailed = true
            setIsAllLoading(false)
            if (isMounted.current) {
              setIsPrinterLoading(false)
            }
            setPrintAlert(res.code)
          }
        }
        //console.log(res, successPrints)
      }

      for (let i = 0; i < printData.length; i++) {
        if (printData[i].section.slug === section) {
          sectionPrint(
            orderItemCopy,
            printData[i],
            printer,
            printsPerBatch,
            1,
            i
          )
          break
        }
      }
    } else {
      handleError("Not connected to printer", dispatchFeedback)
    }
  }

  const handleChangeState = (toState, print = false) => {
    if (toState && print) {
      handlePrint({ ...orderItem })
      sendStateChange(toState, false)
    } else if (!toState && print) {
      handlePrint({ ...orderItem })
    } else if (toState && !print) {
      sendStateChange(toState, false)
    }
  }

  React.useEffect(() => {
    isMounted.current = true

    return () => {
      isMounted.current = false
    }
  })

  return (
    <Box classes={{ root: classes.footer }}>
      <Grid
        container
        direction="row"
        justifyContent="space-around"
        alignItems="center"
      >
        <Grid item>
          <IconButton
            disabled={isAllLoading || isPrinterLoading}
            color="secondary"
            onClick={e => {
              handleChangeState(false, true)
            }}
            classes={{
              root: classes.but,
              disabled: classes.butDisabled,
            }}
          >
            <Badge
              overlap="rectangular"
              badgeContent={
                orderItem.sectionsObj[section].isPrinted ? (
                  <CheckCircleIcon
                    classes={{ root: classes.checkCircleIcon }}
                  />
                ) : (
                  <></>
                )
              }
            >
              {isPrinterLoading ? (
                <CircularProgress color="inherit" size={"2rem"} />
              ) : (
                <LocalPrintshopOutlinedIcon
                  classes={{ root: classes.iconSize }}
                />
              )}
            </Badge>
          </IconButton>
        </Grid>

        {orderItem.sectionsObj[section].sectionStatus === "Preparing" ? (
          <Grid item>
            <IconButton
              disabled={
                isLoading ||
                orderItem.sectionsObj[section].sectionStatus === "Received"
              }
              color="secondary"
              onClick={e => {
                handleChangeState("Received")
              }}
              classes={{
                root: clsx(classes.but, classes.butReceived),
                disabled: classes.butDisabled,
              }}
            >
              {!isLoading ||
              orderItem.sectionsObj[section].sectionStatus === "Received" ? (
                <SettingsBackupRestoreOutlinedIcon
                  classes={{ root: classes.iconSize }}
                />
              ) : (
                <CircularProgress color="inherit" size={"2rem"} />
              )}
            </IconButton>
          </Grid>
        ) : (
          <></>
        )}

        {orderItem.sectionsObj[section].sectionStatus !== "Preparing" ? (
          <>
            <Grid item>
              <IconButton
                disabled={
                  isLoading ||
                  orderItem.sectionsObj[section].sectionStatus === "Preparing"
                }
                color="secondary"
                onClick={e => {
                  handleChangeState("Preparing")
                }}
                classes={{
                  root: clsx(classes.but, classes.butPreparing),
                  disabled: classes.butDisabled,
                }}
              >
                {!isLoading ? (
                  <WhatshotOutlinedIcon classes={{ root: classes.iconSize }} />
                ) : (
                  <CircularProgress color="inherit" size={"2rem"} />
                )}
              </IconButton>
            </Grid>
            {orderItem.sectionsObj[section].isPrinted ? (
              <></>
            ) : orderItem.sectionsObj[section].sectionStatus === "Received" ? (
              <Grid item>
                <Button
                  disabled={
                    isLoading ||
                    isAllLoading ||
                    isPrinterLoading ||
                    orderItem.sectionsObj[section].sectionStatus === "Preparing"
                  }
                  color="secondary"
                  onClick={e => {
                    handleChangeState("Preparing", true)
                  }}
                  classes={{
                    root: clsx(
                      classes.but,
                      classes.butPrintNPrep,
                      classes.butPreparing
                    ),
                    disabled: classes.butDisabled,
                    startIcon: classes.butEndIcons,
                    endIcon: classes.butEndIcons,
                  }}
                  startIcon={
                    !isPrinterLoading ? (
                      <LocalPrintshopOutlinedIcon
                        classes={{ root: classes.printIconSize }}
                      />
                    ) : (
                      <CircularProgress
                        classes={{ root: classes.printProg }}
                        color="inherit"
                        size={"1.75rem"}
                      />
                    )
                  }
                  endIcon={
                    !isLoading ? (
                      <WhatshotOutlinedIcon
                        classes={{ root: classes.printIconSize }}
                      />
                    ) : (
                      <CircularProgress color="inherit" size={"1.75rem"} />
                    )
                  }
                ></Button>
              </Grid>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}

        {orderItem.sectionsObj[section].sectionStatus !== "Received" ? (
          <>
            {orderItem.othersWaiting ? (
              <Grid item>
                <IconButton
                  disabled={
                    isLoading ||
                    orderItem.sectionsObj[section].sectionStatus === "Ready" ||
                    !orderItem.othersWaiting
                  }
                  color="secondary"
                  onClick={e => {
                    handleChangeState("Ready")
                  }}
                  classes={{
                    root: clsx(classes.but, {
                      [classes.butReady]:
                        orderItem.sectionsObj[section].lastUpdateTimeInMin <
                          maxPrepTime ||
                        orderItem.sectionsObj[section].sectionStatus === "Done",
                      [classes.butReadyDanger]:
                        orderItem.sectionsObj[section].lastUpdateTimeInMin >=
                          maxPrepTime &&
                        orderItem.sectionsObj[section].sectionStatus !== "Done",
                    }),
                    disabled: classes.butDisabled,
                  }}
                >
                  {!isLoading ||
                  orderItem.sectionsObj[section].sectionStatus === "Ready" ||
                  !orderItem.othersWaiting ? (
                    <DoneOutlinedIcon classes={{ root: classes.iconSize }} />
                  ) : (
                    <CircularProgress color="inherit" size={"2rem"} />
                  )}
                </IconButton>
              </Grid>
            ) : (
              <></>
            )}
            <Grid item>
              <IconButton
                disabled={
                  isLoading ||
                  orderItem.sectionsObj[section].sectionStatus === "Done" ||
                  (orderItem.othersWaiting &&
                    orderItem.sectionsObj[section].sectionStatus !== "Ready")
                }
                color="secondary"
                onClick={e => {
                  handleChangeState("Done")
                }}
                classes={{
                  root: clsx(classes.but, {
                    [classes.butDone]:
                      orderItem.sectionsObj[section].lastUpdateTimeInMin <
                      (orderItem.othersWaiting ? maxPrepTime / 4 : maxPrepTime),
                    [classes.butDoneDanger]:
                      orderItem.sectionsObj[section].lastUpdateTimeInMin >=
                      (orderItem.othersWaiting ? maxPrepTime / 4 : maxPrepTime),
                  }),
                  disabled: classes.butDisabled,
                }}
              >
                {!isLoading ||
                orderItem.sectionsObj[section].sectionStatus === "Done" ||
                (orderItem.othersWaiting &&
                  orderItem.sectionsObj[section].sectionStatus !== "Ready") ? (
                  <DoneAllOutlinedIcon classes={{ root: classes.iconSize }} />
                ) : (
                  <CircularProgress color="inherit" size={"2rem"} />
                )}
              </IconButton>
            </Grid>
          </>
        ) : (
          <></>
        )}
      </Grid>
    </Box>
  )
}

export default OrderListItemFooter
