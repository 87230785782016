import * as React from "react"
import axios from "axios"
import clsx from "clsx"
import Box from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"
import handleError from "../../../helperFunc/handleError"
import { mainPrintImport, sectionPrint } from "../helperFunc/mainPrintFilter"
import { addSingleOrder } from "../helperFunc/mainImportFilter"

import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  firstRow: {
    width: "100%",
    position: "relative",
  },

  inBox: {
    width: "100%",
    textAlign: "center",
    position: "absolute",
    left: "50%",
    top: "55%",
    msTransform: "translate(-50%, -50%)",
    transform: "translate(-50%, -50%)",
  },

  hideGridItem: {
    opacity: "0%",
  },

  mainBox: {
    paddingTop: "0.55rem",
    paddingLeft: "0.55rem",
    paddingBottom: "0.55rem",
  },
  statusReceived: {
    backgroundColor: theme.palette.common.received,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: `${theme.palette.common.received}CC !important`,
    },
  },
  statusReceivedDisabled: {
    backgroundColor: `${theme.palette.common.received}B3 !important`,
  },
  statusPreparing: {
    backgroundColor: theme.palette.common.preparing,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: `${theme.palette.common.preparing}CC !important`,
    },
  },
  statusPreparingDisabled: {
    backgroundColor: `${theme.palette.common.preparing}B3 !important`,
  },
  statusReady: {
    backgroundColor: theme.palette.common.ready,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: `${theme.palette.common.ready}CC !important`,
    },
  },
  statusReadyDisabled: {
    backgroundColor: `${theme.palette.common.ready}B3 !important`,
  },
  statusDone: {
    backgroundColor: theme.palette.common.done,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: `${theme.palette.common.done}CC !important`,
    },
  },
  statusDoneDisabled: {
    backgroundColor: `${theme.palette.common.done}B3 !important`,
  },
  progressIcon: { marginLeft: "2rem", marginsRight: "2rem" },
}))

const MoreActionsSection = ({
  selectedOrder,
  user,
  dispatchFeedback,
  setAlarm,
  setOrdersIn,
  kitchenSettings,
  ordersIn,
  setSelectedOrder,
  printer,
  setPrintAlert,
  isPrinterLoading,
  setIsPrinterLoading,
}) => {
  const [isLoading, setIsLoading] = React.useState(false)

  const classes = useStyles()

  const handleChangeState = (toState, print = false) => {
    setIsLoading(true)

    if (print) {
      if (printer.current) {
        setIsPrinterLoading(true)
        let printsPerBatch = 7
        let printFailed = false
        let printData = mainPrintImport(
          { ...selectedOrder },
          kitchenSettings.sections
        )

        printer.current.onreceive = function (res) {
          if (res.success) {
            let printjobid = res.printjobid
            let printjobidarray = printjobid.split("BATCH")[1].split("COUNT")
            let batchNo = parseInt(printjobidarray[0])
            let count = parseInt(printjobidarray[1])
            let totalPrintsBatch = Math.ceil(
              printData[count].items.length / printsPerBatch
            )

            if (batchNo >= totalPrintsBatch && count + 1 >= printData.length) {
              setIsPrinterLoading(false)
            } else if (batchNo < totalPrintsBatch) {
              sectionPrint(
                selectedOrder,
                printData[count],
                printer,
                printsPerBatch,
                batchNo + 1,
                count
              )
            } else if (count < printData.length) {
              sectionPrint(
                selectedOrder,
                printData[count + 1],
                printer,
                printsPerBatch,
                1,
                count + 1
              )
            }
          }

          if (!res.success) {
            if (!printFailed) {
              printFailed = true

              setIsPrinterLoading(false)

              setPrintAlert(res.code)
            }
          }
          //console.log(res, successPrints)
        }

        sectionPrint(selectedOrder, printData[0], printer, printsPerBatch, 1, 0)
      } else {
        handleError("Not connected to printer", dispatchFeedback)
      }
    }
    axios
      .post(
        process.env.GATSBY_STRAPI_URL + "/api/kitchen-setting/set-ord-status",
        {
          id: selectedOrder.id,
          newStatus: toState,
        },
        {
          headers: {
            Authorization: `Bearer ${user.jwt}`,
          },
        }
      )
      .then(response => {
        setIsLoading(false)
        const res = addSingleOrder(ordersIn, response.data, kitchenSettings)
        if (res) {
          setOrdersIn(res.orderRes)
          setAlarm(res.alarm)
          setSelectedOrder(res.newOrdOut)
        }
      })
      .catch(error => {
        console.error(error)
        setIsLoading(false)
        handleError(error, dispatchFeedback)
      })
  }

  return (
    <Box classes={{ root: classes.mainBox }}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={1}
        classes={{ root: classes.firstRow }}
      >
        {isLoading || isPrinterLoading ? (
          <Box classes={{ root: classes.inBox }}>
            <CircularProgress color="inherit" size={"1.5rem"} />
          </Box>
        ) : (
          <></>
        )}

        <Grid
          item
          classes={{
            root: clsx({
              [classes.hideGridItem]: isLoading || isPrinterLoading,
            }),
          }}
        >
          <Button
            disableElevation
            onClick={e => {
              handleChangeState("Preparing", true)
            }}
            variant="contained"
            size="medium"
            classes={{
              root: classes.statusPreparing,
              disabled: classes.statusPreparingDisabled,
            }}
            disabled={
              isLoading ||
              isPrinterLoading ||
              selectedOrder.status === "Preparing"
            }
            startIcon={<LocalPrintshopOutlinedIcon />}
          >
            <Typography variant="body1">{"& PREPARING"}</Typography>
          </Button>
        </Grid>
        <Grid
          item
          classes={{
            root: clsx({
              [classes.hideGridItem]: isLoading || isPrinterLoading,
            }),
          }}
        >
          <Button
            variant="contained"
            onClick={e => {
              handleChangeState("Preparing")
            }}
            size="medium"
            classes={{
              root: classes.statusPreparing,
              disabled: classes.statusPreparingDisabled,
            }}
            disableElevation
            disabled={isLoading || selectedOrder.status === "Preparing"}
          >
            <Typography variant="body1">{"PREPARING"}</Typography>
          </Button>
        </Grid>

        <Grid
          item
          classes={{
            root: clsx({
              [classes.hideGridItem]: isLoading || isPrinterLoading,
            }),
          }}
        >
          <Button
            disabled={isLoading || selectedOrder.status === "Received"}
            onClick={e => {
              handleChangeState("Received")
            }}
            disableElevation
            variant="contained"
            size="medium"
            classes={{
              root: classes.statusReceived,
              disabled: classes.statusReceivedDisabled,
            }}
          >
            <Typography variant="body1">{"RECEIVED"}</Typography>
          </Button>
        </Grid>
        <Grid
          item
          classes={{
            root: clsx({
              [classes.hideGridItem]: isLoading || isPrinterLoading,
            }),
          }}
        >
          <Button
            disabled={
              isLoading ||
              selectedOrder.status === "Ready" ||
              selectedOrder.orderType === "dineIn"
            }
            onClick={e => {
              handleChangeState("Ready")
            }}
            variant="contained"
            size="medium"
            disableElevation
            classes={{
              root: classes.statusReady,
              disabled: classes.statusReadyDisabled,
            }}
          >
            <Typography variant="body1">{"READY"}</Typography>
          </Button>
        </Grid>
        <Grid
          item
          classes={{
            root: clsx({
              [classes.hideGridItem]: isLoading || isPrinterLoading,
            }),
          }}
        >
          <Button
            disabled={isLoading || selectedOrder.status === "Done"}
            onClick={e => {
              handleChangeState("Done")
            }}
            variant="contained"
            size="medium"
            disableElevation
            classes={{
              root: classes.statusDone,
              disabled: classes.statusDoneDisabled,
            }}
          >
            <Typography variant="body1">{"DONE"}</Typography>
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

export default MoreActionsSection
